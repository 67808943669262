import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 7월 1일부터 보름간 핀다이렉트 유심요금제 가입자 대상 ‘한여름 밤 힐링 이벤트’ 진행",
  "| 7월 초부터 보름간 ‘핀다이렉트 유심 요금제’ 가입하면, 7만원 상당 ‘코끼리 굿나잇 패키지’ 증정",
  "| ㈜마음수업 운영 모바일 명상앱 ‘코끼리’와 함께 MZ 세대 비대면 마음챙김 도와",
]

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .word-break-all {\
            word-break: break-all;\
          }\
        "
      }
    </style>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/14/AhjhVnFq.png" alt="Figure1" />
      </div>
      <div className="desc">
        △스테이지파이브(공동대표 이제욱ㆍ서상원)가 모바일 명상앱 ‘코끼리’와 함께 7월 1일부터 보름간 이색 콜라보 ‘한여름 밤 힐링
        이벤트’를 진행한다. 이벤트 기간 내 핀다이렉트 유심 요금제 4종 가입자 및 개통한 고객 중 선착순 500명에게 혜택을 제공한다.
      </div>
    </div>
    <p>
      <br />
      스테이지파이브(공동대표 이제욱ㆍ서상원)가 모바일 명상앱 ‘코끼리’를 운영하는 ㈜마음챙김(대표 다니엘 튜더)과 이색 콜라보로 7월
      알뜰폰 요금제 가입자에 힐링을 선사한다.
      <br />
      <br />
      {`스테이지파이브는 내달 1일부터 15일까지, ‘핀다이렉트 유심 요금제’ 4종 가입 고객에 한해 7만 원 상당의 ‘굿나잇 패키지’를 제공하는 <한여름 밤의 힐링 이벤트 ‘굿나잇’>을 진행한다.`}
      <br />
      <br />
      ‘굿나잇 패키지’는 모바일 명상앱 ‘코끼리’ 1년 무료 이용권와 프리미엄 디퓨저로 구성됐다.
      <br />
      <br />
      본 패키지는 핀다이렉트샵을 통해 이벤트 기간 내 알뜰폰 요금제 가입 및 개통을 완료한 고객 중 선착순 500명에게 지급할 예정이다.
      <br />
      <br />
      모바일 명상앱 ‘코끼리’는 ㈜마음수업이 운영하는 멘탈 헬스 케어 플랫폼으로 런칭 1년 6개월 만에 누적 가입자 40만명을 돌파하는
      등 강력한 팬덤을 형성하고 있는 명상 서비스이다
      <br />
      <br />
      스테이지파이브 관계자는 “MZ세대에게 많은 사랑을 받고 있는 ‘핀다이렉트 유심 요금제’가 그 사랑을 돌려드리는 차원에서 이색
      이벤트를 기획했다”고 전하며, “이용자들께서 시간과 장소에 상관없이 합리적인 가격으로 통신을 즐기는 한편, 무더위를 앞두고 마음
      관리도 함께 챙겨 가시길 바라는 뜻에서 멘탈 헬스 케어 플랫폼으로 대표적인 ‘코끼리’와 함께 이벤트를 진행한다”고 설명했다.
      <br />
      <br />
      {`스테이지파이브는 지난 3월, 동급 초저가로 MVNO(알뜰폰) 5G 및 LTE 유심 요금제 4종을 출시한 바 있다. 출시기념 프로모션 할인가로 ▲LTE <완전자유 1.4GB+> 16,000원(데이터 무제한 1.4GB+1Mbps, 음성 및 문자 무제한) ▲LTE <완전자유 11GB+> 월 23,330원 (데이터 제공량 11GB+일2GB+3Mbps) ▲LTE <데이터안심 15GB+> 월 21,000원(데이터 제공량 15GB+3Mbps, 음성100분, 문자100건) ▲<5G 슬림 10GB+> 월 27,000원(데이터 제공량 10GB+1Mbps, 전화통화 및 문자 무제한)을 제공하고 있다. 또한 4GB~150GB를 1년 혹은 평생 제공하는 데이터 추가 제공 프로모션도 함께 진행 중이다. `}
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press14 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 6월 29일 즉시 배포 가능 (6월 30일 조간 요청)"
      pageInfo="*총 2매/ 첨부: 사진 2매"
      title="알뜰폰과 명상 앱의 이색 콜라보!"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press14
